import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '../Typography';
import { paletteV2 } from '../../theme/palette';

export type ColorVariant = keyof typeof paletteV2;

export interface ColorIconProps {
  variant?: 'sm' | 'md';
  color?: ColorVariant | string;
  children?: React.ReactNode;
}

const ColorIcon = (props: ColorIconProps) => {
  const { variant = 'sm', color, children } = props;

  let fontSize = '12px';
  switch (variant) {
    case 'md':
      fontSize = '15px';
      break;
    case 'sm':
    default:
      break;
  }

  const textColorShade = 600;
  const backgroundColorShade = 200;

  /**
   * Let default be hex color + black text color
   * If color prop is actually a paletteV2 key, then we can map to appropriate colors
   */
  let backgroundColor = paletteV2.panda[200];
  let textColor = 'black';

  const isHexCode = color?.charAt(0) === '#';
  const hexRegex = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

  if (color && !isHexCode) {
    Object.entries(paletteV2).forEach(value => {
      const name = value.at(0);
      const values: Record<number, string> = value.at(1);
      if (name !== color) return;
      backgroundColor = values[backgroundColorShade];
      textColor = values[textColorShade];
    });
  }
  if (color && hexRegex.test(color)) {
    backgroundColor = color;
  }

  const containerDimensionsPx = variant === 'sm' ? 16 : 24;

  return (
    <Box
      height={containerDimensionsPx}
      width={containerDimensionsPx}
      bgcolor={backgroundColor}
      borderRadius={2}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      color={textColor}
    >
      <Typography style={{ fontSize }}>{children}</Typography>
    </Box>
  );
};

export default ColorIcon;
