import { Flags } from '../../../FeatureFlags';
import { AvailableIconTypes, IconType } from '../../../Icon';

export interface DrawerNavItem {
  label: string;
  route: string;
  iconType: AvailableIconTypes;
  featureFlag?: Flags;
  disableLink?: boolean;
}

export const routes: Array<DrawerNavItem> = [
  {
    route: '/dashboard/workspaces',
    label: 'Projects',
    iconType: IconType.folder,
    featureFlag: 'custom-workspaces',
  },
  {
    route: '/dashboard/projects',
    label: 'Projects',
    iconType: IconType.folder,
  },
  {
    route: '/dashboard/studio',
    label: 'Studio',
    iconType: IconType.studio,
  },
  {
    route: '/dashboard/avatars',
    label: 'Voices',
    iconType: IconType.voices,
  },
  {
    route: '/dashboard/phonetic',
    label: 'Libraries',
    iconType: IconType.phonetic,
  },
  {
    route: '/dashboard/resources',
    label: 'Resources',
    iconType: IconType.playArrow,
  },
  {
    route: '/dashboard/api',
    label: 'API',
    iconType: IconType.codeSquare,
  },
  {
    route: '/dashboard/integrations',
    label: 'Integrations',
    iconType: IconType.integrations,
  },
];

export const secondaryRoutes: Array<DrawerNavItem> = [
  {
    route: 'https://wellsaidlabs.com/blog/',
    label: 'Blog',
    iconType: IconType.lightbulb,
  },
  {
    route: 'https://portal.productboard.com/wz6hb7phvzs68o4re3z1jfry',
    label: 'Send feedback',
    iconType: IconType.send,
    featureFlag: 'send-feedback-link',
  },
];

export const breadcrumbRoutes: Array<DrawerNavItem> = [
  {
    route: '/dashboard/admin',
    label: 'Admin portal',
    iconType: IconType.userCircle,
  },
  {
    route: '/dashboard/account',
    iconType: IconType.settings01,
    label: 'Account settings',
  },
];

export default { routes };
