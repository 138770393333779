declare global {
  interface Window {
    // See docs for the full list of commands we can implement.
    // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
    zE: (command: string, arg1: string, arg2?: string | string[]) => void;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const showZendeskWebWidget = () => {
  if (typeof window !== 'undefined' && window.zE)
    window.zE('messenger', 'show');
};

export const hideZendeskWebWidget = () => {
  if (typeof window !== 'undefined' && window.zE)
    window.zE('messenger', 'hide');
};

export const setZendeskConversationTags = (tags: string[]) => {
  if (typeof window !== 'undefined' && window.zE)
    window.zE('messenger:set', 'conversationTags', tags);
};
