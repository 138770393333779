import createPalette from '@material-ui/core/styles/createPalette';

const COLOR_DISABLED = '#666666';
export const SHARED_WHITE = `#FFFFFF`;
const SHARED_BLACK = `#000000`;
const SHARED_GREY = `#999999`;

/**
 * @description Type that mirrors design system color palette - note this is subject to radical changes
 * {@link https://www.figma.com/file/TWgty8gcTip6JWbfKJigFb/WL-Styles-v1.0-Breve?node-id=291-5249&t=fe4DaCAvfphLmlan-0 | see figma}
 */
export type PaletteBridge = {
  text: {
    offset1: string;
    offset2: string;
    offset3: string;
  };
  strokes: {
    default: string;
    hover: string;
    active: string;
  };
  statuses: {
    errorText: string;
    errorButton: string;
    errorHover: string;
    success: string;
  };
  accents: {
    navy: string;
    alternateGreen: string;
    purple1: string;
    purple2: string;
    purple3: string;
    pink: string;
    // not in use...
    purpleGradient: {
      start: string;
      end: string;
    };
    // linear primary gradient
    greenGradient: {
      start: string;
      end: string;
    };
    primaryOpaque: {
      20: string;
      30: string;
      50: string;
    };
  };
  surfaces: {
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
  };
  buttons: {
    primary: {
      default: string;
      hover: string;
    };
    secondary: {
      default: string;
      hover: string;
    };
  };
  greens: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    lime: {
      100: string;
    };
  };
  blues: {
    primary: string;
  };
  icons: {
    offset1: string;
    offset2: string;
    offset3: string;
  };
};

/**
 * @description this object serves as an extensible palette before migration to MUI v5 - note this is subject to radical changes
 * {@link https://www.figma.com/file/TWgty8gcTip6JWbfKJigFb/WL-Styles-v1.0-Breve?node-id=291-5249&t=fe4DaCAvfphLmlan-0 | see figma}
 */
export const paletteBridge: PaletteBridge = {
  text: {
    offset1: SHARED_WHITE,
    offset2: SHARED_BLACK,
    offset3: SHARED_GREY, // equivalent to textSecondary
  },
  strokes: {
    default: SHARED_GREY,
    hover: SHARED_WHITE,
    active: `#38EF7D`,
  },
  statuses: {
    errorText: `#F16A50`,
    errorButton: `#CB1240`,
    errorHover: `#9C0A2E`,
    success: `#008646`,
  },
  accents: {
    navy: '#2C3E50',
    alternateGreen: `#11998E`,
    purple1: `#8E44AD`,
    purple2: `#5D32D7`,
    purple3: `#331293`,
    pink: `#FF3366`,
    // not in use...
    purpleGradient: {
      start: `#8E44AD`,
      end: `#2C3E50`,
    },
    // linear primary gradient
    greenGradient: {
      start: `#38EF7D`,
      end: `#11998E`,
    },
    primaryOpaque: {
      // should align w primary color
      20: 'rgba(56, 239, 125, 0.2)',
      30: 'rgba(56, 239, 125, 0.3)',
      50: 'rgba(56, 239, 125, 0.5)',
    },
  },
  surfaces: {
    1: SHARED_WHITE,
    2: SHARED_BLACK,
    3: `#222222`,
    4: `#333333`,
    5: `#444444`,
  },
  buttons: {
    primary: {
      default: `#38EF7D`,
      hover: `#5BFF99`,
    },
    secondary: {
      default: `#444444`,
      hover: `#555555`,
    },
  },
  greens: {
    primary: `#38EF7D`,
    secondary: `#32D770`,
    tertiary: `#27A757`,
    quaternary: `#BBF8CA`,
    lime: {
      100: '#9CF7BE',
    },
  },
  blues: {
    primary: `#8ABFFF`,
  },
  icons: {
    offset1: SHARED_WHITE,
    offset2: SHARED_BLACK,
    offset3: SHARED_GREY,
  },
};

interface PaletteV2 {
  grey: {
    0: string;
    20: string;
    25: string;
    50: string;
    75: string;
    100: string;
    200: string;
    250: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
    1100: string;
  };
  lime: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  juniper: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  blueberry: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  grape: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  raspberry: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  cherry: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  orange: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  mango: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  plum: {
    0: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  };
  mint: {
    300: string;
    400: string;
  };
  azure: {
    200: string;
    600: string;
  };
  lavender: {
    200: string;
    600: string;
  };
  punch: {
    200: string;
    600: string;
  };
  papaya: {
    200: string;
    600: string;
  };
  sunflower: {
    200: string;
    600: string;
  };
  chartreuse: {
    200: string;
    600: string;
  };
  sapphire: {
    200: string;
    600: string;
  };
  panda: {
    200: string;
    600: string;
  };
}

export const paletteV2: PaletteV2 = {
  grey: {
    0: '#FCFCFC',
    20: '#F8F8F8',
    25: '#F0F0F0',
    50: '#DBDBDB',
    75: '#C7C7C7',
    100: '#BDBDBD',
    200: '#AFAFAF',
    250: '#0A0A0A',
    300: '#999999',
    400: '#646464',
    500: '#444444',
    600: '#3A3A3A',
    700: '#333333',
    800: '#2C2C2C',
    900: '#232323',
    1000: '#1E1E1E',
    1100: '#111111',
  },
  lime: {
    0: '#DEFCE9',
    50: '#BDFAD4',
    100: '#9CF7BE',
    200: '#7AF4A8',
    300: '#59F293',
    400: '#38EF7D',
    500: '#2DBF64',
    600: '#27A757',
    700: '#228F4B',
    800: '#1C783F',
    900: '#166032',
  },
  juniper: {
    0: '#CFFDF8',
    50: '#9FFAF1',
    100: '#0FF3DD',
    200: '#3FF5E4',
    300: '#0FF3DD',
    400: '#0AC5B3',
    500: '#12A594',
    600: '#07857B',
    700: '#0B544A',
    800: '#09443C',
    900: '#083932',
  },
  blueberry: {
    0: '#DEEFFF',
    50: '#BCDFFF',
    100: '#9BCEFF',
    200: '#79BEFF',
    300: '#58AEFF',
    400: '#369EFF',
    500: '#047AEA',
    600: '#086FD1',
    700: '#0954A5',
    800: '#0B4B8F',
    900: '#0D3868',
  },
  grape: {
    0: '#F9F2FE',
    50: '#ECD7FB',
    100: '#DFBDF8',
    200: '#D9AFF6',
    300: '#D2A2F5',
    400: '#BF7AF0',
    500: '#8E4EC6',
    600: '#7938B2',
    700: '#5F2D84',
    800: '#4E2667',
    900: '#432155',
  },
  raspberry: {
    0: '#FAE1F1',
    50: '#F6C3E2',
    100: '#F1A5D4',
    200: '#EC87C6',
    300: '#E869B7',
    400: '#E34BA9',
    500: '#E0359F',
    600: '#DA2294',
    700: '#AE1B76',
    800: '#831459',
    900: '#6D114A',
  },
  cherry: {
    0: '#F5D0D9',
    50: '#F7A7BB',
    100: '#F37A98',
    200: '#DB5979',
    300: '#D54166',
    400: '#CB1240',
    500: '#9C0A2E',
    600: '#8E0D2D',
    700: '#7A0B26',
    800: '#660920',
    900: '#51071A',
  },
  orange: {
    0: '#FACDC5',
    50: '#F8B4A7',
    100: '#F69C8A',
    200: '#F48F7C',
    300: '#F3836D',
    400: '#F16A50',
    500: '#D84C31',
    600: '#B2290E',
    700: '#95220C',
    800: '#771B0A',
    900: '',
  },
  mango: {
    0: '#FDF5CC',
    50: '#FFF0A7',
    100: '#FFE97C',
    200: '#FFE250',
    300: '#FFDB24',
    400: '#F7CE00',
    500: '#DEB900',
    600: '#C6A500',
    700: '#AD9000',
    800: '#947C00',
    900: '#7C6700',
  },
  plum: {
    0: '#EAE4FA',
    50: '#D5CAF5',
    100: '#C0AFF0',
    200: '#AB95EA',
    300: '#967AE5',
    400: '#8160E0',
    500: '#6C45DB',
    600: '#572AD6',
    700: '#4B25BB',
    800: '#411FA1',
    900: '#361A86',
  },
  mint: {
    300: '#7FED9B',
    400: '#61CF7C',
  },
  azure: {
    200: '#9BC8FF',
    600: '#1D3048',
  },
  lavender: {
    200: '#D6B5FF',
    600: '#3B3049',
  },
  punch: {
    200: '#F1A9BC',
    600: '#601528',
  },
  papaya: {
    200: '#FFC4A3',
    600: '#453024',
  },
  sunflower: {
    200: '#FBD191',
    600: '#1D3048',
  },
  chartreuse: {
    200: '#F8FFA4',
    600: '#383A28',
  },
  sapphire: {
    200: '#A7EEDD',
    600: '#214A40',
  },
  panda: {
    200: '#F8F8F8',
    600: '#232323',
  },
};

const BOX_SHADOW_OPACITY = '32';

/**
 * @description palette instance - note any hardcoded color is not within the design system
 */
const palette = createPalette({
  type: 'dark',
  background: {
    default: paletteBridge.surfaces[2],
    paper: paletteBridge.surfaces[3],
    paperSecondary: '#111111',
  },
  primary: {
    light: paletteBridge.greens.quaternary,
    main: paletteBridge.greens.primary,
    dark: paletteBridge.greens.tertiary,
  },
  secondary: {
    light: '#566473',
    main: paletteBridge.accents.navy,
    dark: '#1e2b38', // no longer in use
  },
  error: {
    main: paletteBridge.statuses.errorButton,
    dark: paletteBridge.statuses.errorHover,
    light: paletteBridge.statuses.errorText,
  },
  warning: {
    main: '#FFC107',
  },
  grey: {
    500: SHARED_GREY,
    700: '#666666',
    800: paletteBridge.surfaces[4],
    900: paletteBridge.surfaces[3],
    A100: '#232323',
    A200: '#EEEEEE',
    A400: '#1E1E1E',
  },
  accent: {
    main: '#8E44AD',
    primaryOpaque: {
      // should align w primary color
      20: paletteBridge.accents.primaryOpaque[20],
      30: paletteBridge.accents.primaryOpaque[30],
      50: paletteBridge.accents.primaryOpaque[50],
    },
  },
  text: {
    secondary: SHARED_GREY,
    disabled: COLOR_DISABLED,
  },
  action: {
    // NOTE: this is not random, it is chosen specifically such that the action overlay of
    // rgba(255,255,255,0.14) on top of a #000 background will result in #222
    hoverOpacity: 0.14,
  },
  inputs: {
    // default input color, placeholder/border
    default: paletteBridge.strokes.default,
    active: paletteBridge.strokes.hover, // hover state
    focus: paletteBridge.strokes.active,
  },
  contained: {
    hover: paletteBridge.buttons.primary.hover,
  },
  slider: {
    track: {
      default: {
        backgroundColor: paletteV2.grey[400],
      },
      loudness: {
        backgroundColor: paletteV2.juniper[600],
      },
      tempo: {
        backgroundColor: paletteV2.mango[600],
      },
      pitch: {
        backgroundColor: paletteV2.plum[600],
      },
      pause: {
        backgroundColor: paletteV2.raspberry[600],
      },
    },
    mark: '#777',
    thumb: {
      loudness: {
        backgroundColor: paletteV2.juniper[400],
        boxShadowColor: `${paletteV2.juniper[400]}${BOX_SHADOW_OPACITY}`,
      },
      tempo: {
        backgroundColor: paletteV2.mango[400],
        boxShadowColor: `${paletteV2.mango[400]}${BOX_SHADOW_OPACITY}`,
      },
      pitch: {
        backgroundColor: paletteV2.plum[400],
        boxShadowColor: `${paletteV2.plum[400]}${BOX_SHADOW_OPACITY}`,
      },
      pause: {
        backgroundColor: paletteV2.raspberry[400],
        boxShadowColor: `${paletteV2.raspberry[400]}${BOX_SHADOW_OPACITY}`,
      },
      inactive: {
        backgroundColor: paletteBridge.surfaces[5],
      },
    },
  },
  ui: {
    button: {
      primary: {
        surface: {
          default: paletteV2.lime[300],
          hover: paletteV2.lime[200],
        },
        text: {
          default: paletteV2.grey[1000],
        },
      },
      /**
       * @todo: update this variation for dark mode
       */
      secondary: {
        surface: {
          default: SHARED_WHITE,
          hover: paletteV2.grey[25],
        },
        text: {
          default: `${paletteV2.grey[1000]} `,
        },
        border: {
          default: `${paletteV2.grey[1000]} !important`,
        },
        hover: {
          boxShadow: {
            // note: this is subject to change
            default: `0px 1px 3px 1px rgba(0, 0, 0, 0.13), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)`,
          },
        },
        focus: {
          border: {
            default: `${paletteV2.grey[1000]} !important`,
          },
          outline: {
            // todo: register color
            default: `#8B8B8B !important`,
          },
        },
      },
    },
    background: {
      surface: {
        default: SHARED_BLACK,
      },
    },
    input: {
      primary: {
        text: {
          default: paletteV2.grey[0],
          placeholder: paletteV2.grey[75],
          label: SHARED_WHITE,
        },
        surface: {
          default: 'inherit',
        },
        stroke: {
          default: paletteV2.grey[300],
          focus: paletteV2.lime[100],
          active: paletteV2.lime[400],
        },
      },
      password: {
        icon: {
          default: paletteV2.grey[300],
        },
      },
    },
  },
});

/**
 * Below is a list of colors to register to the palette based on harcoded usages
 *
 * DestructiveButton:hover => '#D32954'
 *
 * Audio Slider and Cue Slider:
 * -- track => #474747
 * -- mark => #777
 *
 * Footer
 * - linkedin => #0a66c2
 * - heartIcon => #ff0201
 *
 * SubscriptionPaymentCard => #111111
 *
 * Hex references to update that exist in the palette
 *
 * 1. LabelButton
 * 2. Editor
 * 3. CardForm
 *
 * Other considerations:
 * 1. consolidating rgba usages
 */

export default palette;
