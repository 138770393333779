import { Box, Grid, GridProps, makeStyles } from '@material-ui/core';
import Link, { LinkProps } from 'next/link';
import React from 'react';
import Icon from '../Icon';
import Typography from '../Typography';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  logo: {
    height: 24,
  },
  menuHeading: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  menuList: {
    // marginBottom: theme.spacing(2),
  },
  menuListItem: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuListItemAnchor: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
      outline: 'none',
      cursor: 'pointer',
    },
  },
  linkedin: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0a66c2',
    width: 22,
    height: 22,
    borderRadius: 2,
    '& > img': {
      width: 16,
      height: 'auto',
    },
  },
  youtube: {
    marginRight: theme.spacing(1),
    lineHeight: 0,
    '& > img': {
      height: 20,
      width: 'auto',
    },
  },
  verticalFlexGridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  heartIcon: {
    color: '#ff0201',
    verticalAlign: 'sub',
    marginLeft: 2,
    marginRight: 2,
  },
}));

const menuListColumns: GridProps = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 2,
};

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container spacing={8}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          className={classes.verticalFlexGridItem}
        >
          <Box marginBottom={2}>
            <img
              className={classes.logo}
              src="/static/images/logo/mark.svg"
              alt="WellSaid Logo Icon"
            />
          </Box>
          <Typography
            isLegacy
            color="textSecondary"
            gutterBottom
            style={{ fontWeight: 'bold' }}
          >
            &copy; WellSaid Labs, Inc.&nbsp;{new Date().getFullYear()}
          </Typography>
          <Box marginBottom={{ xs: 2, sm: 5 }}>
            <Typography
              isLegacy
              color="textSecondary"
            >{`All rights reserved`}</Typography>
          </Box>
          <Box style={{ marginTop: 'auto' }}>
            <Typography isLegacy color="textSecondary">
              {`Made with `}
              <Icon
                type="favorite"
                titleAccess="love"
                className={classes.heartIcon}
                filled
              />
              {` in `}
              <b>{`Seattle, WA`}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item {...menuListColumns}>
          <FooterMenuList
            title="Products"
            listItems={[
              { title: 'Studio', href: '/features/studio' },
              { title: 'API', href: '/features/api' },
              { title: 'Avatars', href: '/products/custom-voices' },
            ]}
          />
        </Grid>
        <Grid item {...menuListColumns}>
          <FooterMenuList
            title="Resources"
            listItems={[
              {
                title: 'eBook',
                href: 'https://hi.wellsaidlabs.com/voiceavatarsebook',
              },
              { title: 'Help Desk', href: 'https://help.wellsaidlabs.com' },
              {
                title: 'Talk with us',
                href: 'https://hi.wellsaidlabs.com/teams',
              },
              { title: 'Blog', href: 'https://blog.wellsaidlabs.com' },
            ]}
          />
        </Grid>
        <Grid item {...menuListColumns}>
          <FooterMenuList
            title="Company"
            listItems={[
              { title: 'Home', href: '/' },
              { title: 'Ethics', href: '/ethics' },
              {
                title: 'Careers',
                href: 'https://wellsaidlabs.com/careers/',
              },
              {
                title: 'Trust Report',
                href: 'https://trust.wellsaidlabs.com/',
              },
            ]}
          />
        </Grid>
        <Grid
          item
          {...menuListColumns}
          className={classes.verticalFlexGridItem}
        >
          <Box marginBottom="auto">
            <FooterMenuList
              title="Legal"
              listItems={[
                {
                  title: 'Terms of Service',
                  href: {
                    pathname: '/legal',
                    query: { document: 'terms_of_service', exit_to: '/' },
                  },
                },
                {
                  title: 'Privacy Policy',
                  href: {
                    pathname: '/legal',
                    query: { document: 'privacy_policy', exit_to: '/' },
                  },
                },
              ]}
            />
          </Box>
          <Box display="flex" alignItems="center" marginTop={2}>
            <a
              href="https://www.youtube.com/channel/UCKost_qEkRO3jARqgY1Uqiw"
              className={classes.youtube}
            >
              <img src="/static/images/social/youtube.png" alt="YouTube icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/wellsaidlabs/"
              className={classes.linkedin}
            >
              <img
                src="/static/images/social/linkedin.svg"
                alt="LinkedIn icon"
              />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FooterMenuList: React.FC<{
  title: string;
  listItems: Array<{ title: string; href: LinkProps['href'] }>;
}> = ({ title, listItems }) => {
  const classes = useStyles();
  return (
    <nav>
      <Typography isLegacy variant="body1" className={classes.menuHeading}>
        {title}
      </Typography>
      <ul className={classes.menuList}>
        {listItems.map(item => (
          <li key={`${item.title}`} className={classes.menuListItem}>
            {item.href.toString().startsWith('http') ? (
              <Typography
                isLegacy
                className={classes.menuListItemAnchor}
                variant="button"
                color="textSecondary"
                // @ts-ignore
                href={item.href}
                component="a"
              >
                {item.title}
              </Typography>
            ) : (
              <Link href={item.href} passHref>
                <Typography
                  isLegacy
                  className={classes.menuListItemAnchor}
                  variant="button"
                  color="textSecondary"
                  component="a"
                >
                  {item.title}
                </Typography>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Footer;
