import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
import * as Types from '../../../server/graphql/__generated.types';

import {
  SubscriptionPlanWithDisplayInformationFragmentDoc,
  UserSubscriptionFragmentDoc,
} from '../fragments.generated';
import { ProductAccessPeriodUsageFragmentDoc } from '../ProductUsage/operation.generated';

const defaultOptions = {} as const;
export type UserSubscriptionPlansQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserSubscriptionPlansQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    subscriptionPlans: Array<{
      __typename?: 'SubscriptionPlan';
      id: string;
      active: boolean;
      amount: number;
      currency: string;
      interval: Types.SubscriptionInterval;
      intervalCount: number;
      productId: string;
      displayName: string;
      selectable: boolean;
      auth0RoleId: string;
      managed: boolean;
      displayInformation: {
        __typename?: 'SubscriptionPlanDisplayInformation';
        displayName: string;
        displaySubheading: string;
        displayTertiaryHeading: string;
        displayActorCount: number;
        displayActorVariantCount: string;
        displayPrice: string;
        discountLabel?: string | null;
        badgeLabel?: string | null;
        lineItemsHeader?: string | null;
        lineItems: Array<{
          __typename?: 'SubscriptionPlanLineItems';
          text: string;
          emphasisLabel?: string | null;
        }>;
      };
      accessLimits: {
        __typename?: 'ProductAccessLimits';
        projectsLimit: number;
        clipGenerationLimit: number;
      };
      discount?: Array<{
        __typename?: 'Discount';
        amount: number;
        coupon?: {
          __typename?: 'Coupon';
          name: string;
          amountOff?: number | null;
          percentOff?: number | null;
        } | null;
      }> | null;
    }>;
  };
};

export type UserSubscriptionQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserSubscriptionQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    subscription?: {
      __typename?: 'UserSubscription';
      id: string;
      isTeamSubscription: boolean;
      customerId: string;
      created: number;
      currentPeriodStart: number;
      currentPeriodEnd: number;
      cancelAtPeriodEnd: boolean;
      cancelAt?: number | null;
      canceledAt?: number | null;
      status: Types.SubscriptionStatus;
      churnkeyAuthHash?: string | null;
      billing?: {
        __typename?: 'SubscriptionBilling';
        source?: {
          __typename?: 'BillingSource';
          id: string;
          name: string;
          brand: string;
          expiry: string;
          last4: string;
        } | null;
        addressInfo?: {
          __typename?: 'AddressInfo';
          shipping?: {
            __typename?: 'Shipping';
            name?: string | null;
            address?: {
              __typename?: 'Address';
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              postalCode?: string | null;
              country?: string | null;
            } | null;
          } | null;
          taxId?: { __typename?: 'TaxId'; type: string; value: string } | null;
        } | null;
      } | null;
      discount?: {
        __typename?: 'SubscriptionDiscount';
        id?: string | null;
        coupon?: {
          __typename?: 'SubscriptionCoupon';
          id: string;
          amount_off?: number | null;
          percent_off?: number | null;
          valid: boolean;
        } | null;
      } | null;
      plan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        active: boolean;
        amount: number;
        currency: string;
        interval: Types.SubscriptionInterval;
        intervalCount: number;
        productId: string;
        displayName: string;
        selectable: boolean;
        auth0RoleId: string;
        managed: boolean;
        accessLimits: {
          __typename?: 'ProductAccessLimits';
          projectsLimit: number;
          clipGenerationLimit: number;
        };
        discount?: Array<{
          __typename?: 'Discount';
          amount: number;
          coupon?: {
            __typename?: 'Coupon';
            name: string;
            amountOff?: number | null;
            percentOff?: number | null;
          } | null;
        }> | null;
      };
    } | null;
  };
};

export type UserSubscriptionUpdatePreviewQueryVariables = Types.Exact<{
  inputs: Types.SubscriptionUpdatePreviewInputs;
}>;

export type UserSubscriptionUpdatePreviewQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    subscriptionUpdatePreview?: {
      __typename?: 'SubscriptionUpdatePreview';
      amountDue: number;
      prorationDate: number;
      prorationAmount: number;
      remainingAmountInPeriodExcludingTax: number;
      tax?: number | null;
      totalExcludingTax?: number | null;
      proration: {
        __typename?: 'SubscriptionUpdatePreviewAmount';
        amount: number;
        description: string;
      };
      remainingInPeriodExcludingTax: {
        __typename?: 'SubscriptionUpdatePreviewAmount';
        amount: number;
        description: string;
      };
      discount?: Array<{
        __typename?: 'Discount';
        amount: number;
        coupon?: {
          __typename?: 'Coupon';
          name: string;
          amountOff?: number | null;
          percentOff?: number | null;
        } | null;
      }> | null;
    } | null;
    subscription?: {
      __typename?: 'UserSubscription';
      id: string;
      billing?: {
        __typename?: 'SubscriptionBilling';
        source?: { __typename?: 'BillingSource'; last4: string } | null;
      } | null;
    } | null;
  };
};

export type UpgradeUserSubscriptionMutationVariables = Types.Exact<{
  inputs: Types.UserSubscriptionUpgradeInputs;
}>;

export type UpgradeUserSubscriptionMutation = {
  __typename?: 'Mutation';
  userSubscriptionUpgrade: {
    __typename?: 'UserSubscriptionUpgradeMutationResponse';
    code: string;
    success: boolean;
    message: string;
    subscription?: {
      __typename?: 'UserSubscription';
      id: string;
      isTeamSubscription: boolean;
      customerId: string;
      created: number;
      currentPeriodStart: number;
      currentPeriodEnd: number;
      cancelAtPeriodEnd: boolean;
      cancelAt?: number | null;
      canceledAt?: number | null;
      status: Types.SubscriptionStatus;
      churnkeyAuthHash?: string | null;
      billing?: {
        __typename?: 'SubscriptionBilling';
        source?: {
          __typename?: 'BillingSource';
          id: string;
          name: string;
          brand: string;
          expiry: string;
          last4: string;
        } | null;
        addressInfo?: {
          __typename?: 'AddressInfo';
          shipping?: {
            __typename?: 'Shipping';
            name?: string | null;
            address?: {
              __typename?: 'Address';
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              postalCode?: string | null;
              country?: string | null;
            } | null;
          } | null;
          taxId?: { __typename?: 'TaxId'; type: string; value: string } | null;
        } | null;
      } | null;
      discount?: {
        __typename?: 'SubscriptionDiscount';
        id?: string | null;
        coupon?: {
          __typename?: 'SubscriptionCoupon';
          id: string;
          amount_off?: number | null;
          percent_off?: number | null;
          valid: boolean;
        } | null;
      } | null;
      plan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        active: boolean;
        amount: number;
        currency: string;
        interval: Types.SubscriptionInterval;
        intervalCount: number;
        productId: string;
        displayName: string;
        selectable: boolean;
        auth0RoleId: string;
        managed: boolean;
        accessLimits: {
          __typename?: 'ProductAccessLimits';
          projectsLimit: number;
          clipGenerationLimit: number;
        };
        discount?: Array<{
          __typename?: 'Discount';
          amount: number;
          coupon?: {
            __typename?: 'Coupon';
            name: string;
            amountOff?: number | null;
            percentOff?: number | null;
          } | null;
        }> | null;
      };
    } | null;
    userProductAccessPeriodUsage?: {
      __typename?: 'ProductAccessPeriodUsage';
      id: string;
      projects: number;
      clipsGenerated: number;
      periodStart: number;
      periodEnd: number;
      usageScope: Types.ProductAccessUsageScope;
    } | null;
    teamProductAccessPeriodUsage?: {
      __typename?: 'ProductAccessPeriodUsage';
      id: string;
      projects: number;
      clipsGenerated: number;
      periodStart: number;
      periodEnd: number;
      usageScope: Types.ProductAccessUsageScope;
    } | null;
  };
};

export type ChangeUserSubscriptionPlanMutationVariables = Types.Exact<{
  inputs: Types.UserSubscriptionPlanChangeInputs;
}>;

export type ChangeUserSubscriptionPlanMutation = {
  __typename?: 'Mutation';
  userSubscriptionPlanChange: {
    __typename?: 'UserSubscriptionPlanChangeMutationResponse';
    code: string;
    success: boolean;
    message: string;
    subscription?: {
      __typename?: 'UserSubscription';
      id: string;
      isTeamSubscription: boolean;
      customerId: string;
      created: number;
      currentPeriodStart: number;
      currentPeriodEnd: number;
      cancelAtPeriodEnd: boolean;
      cancelAt?: number | null;
      canceledAt?: number | null;
      status: Types.SubscriptionStatus;
      churnkeyAuthHash?: string | null;
      billing?: {
        __typename?: 'SubscriptionBilling';
        source?: {
          __typename?: 'BillingSource';
          id: string;
          name: string;
          brand: string;
          expiry: string;
          last4: string;
        } | null;
        addressInfo?: {
          __typename?: 'AddressInfo';
          shipping?: {
            __typename?: 'Shipping';
            name?: string | null;
            address?: {
              __typename?: 'Address';
              line1?: string | null;
              line2?: string | null;
              city?: string | null;
              state?: string | null;
              postalCode?: string | null;
              country?: string | null;
            } | null;
          } | null;
          taxId?: { __typename?: 'TaxId'; type: string; value: string } | null;
        } | null;
      } | null;
      discount?: {
        __typename?: 'SubscriptionDiscount';
        id?: string | null;
        coupon?: {
          __typename?: 'SubscriptionCoupon';
          id: string;
          amount_off?: number | null;
          percent_off?: number | null;
          valid: boolean;
        } | null;
      } | null;
      plan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        active: boolean;
        amount: number;
        currency: string;
        interval: Types.SubscriptionInterval;
        intervalCount: number;
        productId: string;
        displayName: string;
        selectable: boolean;
        auth0RoleId: string;
        managed: boolean;
        accessLimits: {
          __typename?: 'ProductAccessLimits';
          projectsLimit: number;
          clipGenerationLimit: number;
        };
        discount?: Array<{
          __typename?: 'Discount';
          amount: number;
          coupon?: {
            __typename?: 'Coupon';
            name: string;
            amountOff?: number | null;
            percentOff?: number | null;
          } | null;
        }> | null;
      };
    } | null;
    userProductAccessPeriodUsage?: {
      __typename?: 'ProductAccessPeriodUsage';
      id: string;
      projects: number;
      clipsGenerated: number;
      periodStart: number;
      periodEnd: number;
      usageScope: Types.ProductAccessUsageScope;
    } | null;
    teamProductAccessPeriodUsage?: {
      __typename?: 'ProductAccessPeriodUsage';
      id: string;
      projects: number;
      clipsGenerated: number;
      periodStart: number;
      periodEnd: number;
      usageScope: Types.ProductAccessUsageScope;
    } | null;
  };
};

export const UserSubscriptionPlansDocument = gql`
  query UserSubscriptionPlans {
    user {
      id
      subscriptionPlans {
        ...SubscriptionPlanWithDisplayInformationFragment
      }
    }
  }
  ${SubscriptionPlanWithDisplayInformationFragmentDoc}
`;

/**
 * __useUserSubscriptionPlans__
 *
 * To run a query within a React component, call `useUserSubscriptionPlans` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionPlans` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionPlans({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionPlans(
  baseOptions?: Apollo.QueryHookOptions<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSubscriptionPlansQuery, UserSubscriptionPlansQueryVariables>(
    UserSubscriptionPlansDocument,
    options
  );
}
export function useUserSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >(UserSubscriptionPlansDocument, options);
}
export type UserSubscriptionPlansHookResult = ReturnType<typeof useUserSubscriptionPlans>;
export type UserSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlansLazyQuery
>;
export type UserSubscriptionPlansQueryResult = Apollo.QueryResult<
  UserSubscriptionPlansQuery,
  UserSubscriptionPlansQueryVariables
>;
export const UserSubscriptionDocument = gql`
  query UserSubscription {
    user {
      id
      subscription {
        ...UserSubscriptionFragment
      }
    }
  }
  ${UserSubscriptionFragmentDoc}
`;

/**
 * __useUserSubscription__
 *
 * To run a query within a React component, call `useUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscription(
  baseOptions?: Apollo.QueryHookOptions<
    UserSubscriptionQuery,
    UserSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(
    UserSubscriptionDocument,
    options
  );
}
export function useUserSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionQuery,
    UserSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(
    UserSubscriptionDocument,
    options
  );
}
export type UserSubscriptionHookResult = ReturnType<typeof useUserSubscription>;
export type UserSubscriptionLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionLazyQuery
>;
export type UserSubscriptionQueryResult = Apollo.QueryResult<
  UserSubscriptionQuery,
  UserSubscriptionQueryVariables
>;
export const UserSubscriptionUpdatePreviewDocument = gql`
  query UserSubscriptionUpdatePreview($inputs: SubscriptionUpdatePreviewInputs!) {
    user {
      id
      subscriptionUpdatePreview(inputs: $inputs) {
        amountDue
        proration {
          amount
          description
        }
        remainingInPeriodExcludingTax {
          amount
          description
        }
        prorationDate
        prorationAmount
        remainingAmountInPeriodExcludingTax
        tax
        totalExcludingTax
        discount {
          amount
          coupon {
            name
            amountOff
            percentOff
          }
        }
      }
      subscription {
        id
        billing {
          source {
            last4
          }
        }
      }
    }
  }
`;

/**
 * __useUserSubscriptionUpdatePreview__
 *
 * To run a query within a React component, call `useUserSubscriptionUpdatePreview` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionUpdatePreview` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionUpdatePreview({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUserSubscriptionUpdatePreview(
  baseOptions: Apollo.QueryHookOptions<
    UserSubscriptionUpdatePreviewQuery,
    UserSubscriptionUpdatePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserSubscriptionUpdatePreviewQuery,
    UserSubscriptionUpdatePreviewQueryVariables
  >(UserSubscriptionUpdatePreviewDocument, options);
}
export function useUserSubscriptionUpdatePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionUpdatePreviewQuery,
    UserSubscriptionUpdatePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserSubscriptionUpdatePreviewQuery,
    UserSubscriptionUpdatePreviewQueryVariables
  >(UserSubscriptionUpdatePreviewDocument, options);
}
export type UserSubscriptionUpdatePreviewHookResult = ReturnType<
  typeof useUserSubscriptionUpdatePreview
>;
export type UserSubscriptionUpdatePreviewLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionUpdatePreviewLazyQuery
>;
export type UserSubscriptionUpdatePreviewQueryResult = Apollo.QueryResult<
  UserSubscriptionUpdatePreviewQuery,
  UserSubscriptionUpdatePreviewQueryVariables
>;
export const UpgradeUserSubscriptionDocument = gql`
  mutation UpgradeUserSubscription($inputs: UserSubscriptionUpgradeInputs!) {
    userSubscriptionUpgrade(inputs: $inputs) {
      code
      success
      message
      subscription {
        ...UserSubscriptionFragment
      }
      userProductAccessPeriodUsage {
        ...ProductAccessPeriodUsageFragment
      }
      teamProductAccessPeriodUsage {
        ...ProductAccessPeriodUsageFragment
      }
    }
  }
  ${UserSubscriptionFragmentDoc}
  ${ProductAccessPeriodUsageFragmentDoc}
`;
export type UpgradeUserSubscriptionMutationFn = Apollo.MutationFunction<
  UpgradeUserSubscriptionMutation,
  UpgradeUserSubscriptionMutationVariables
>;

/**
 * __useUpgradeUserSubscription__
 *
 * To run a mutation, you first call `useUpgradeUserSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeUserSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeUserSubscription, { data, loading, error }] = useUpgradeUserSubscription({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpgradeUserSubscription(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeUserSubscriptionMutation,
    UpgradeUserSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpgradeUserSubscriptionMutation,
    UpgradeUserSubscriptionMutationVariables
  >(UpgradeUserSubscriptionDocument, options);
}
export type UpgradeUserSubscriptionHookResult = ReturnType<
  typeof useUpgradeUserSubscription
>;
export type UpgradeUserSubscriptionMutationResult =
  Apollo.MutationResult<UpgradeUserSubscriptionMutation>;
export type UpgradeUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpgradeUserSubscriptionMutation,
  UpgradeUserSubscriptionMutationVariables
>;
export const ChangeUserSubscriptionPlanDocument = gql`
  mutation ChangeUserSubscriptionPlan($inputs: UserSubscriptionPlanChangeInputs!) {
    userSubscriptionPlanChange(inputs: $inputs) {
      code
      success
      message
      subscription {
        ...UserSubscriptionFragment
      }
      userProductAccessPeriodUsage {
        ...ProductAccessPeriodUsageFragment
      }
      teamProductAccessPeriodUsage {
        ...ProductAccessPeriodUsageFragment
      }
    }
  }
  ${UserSubscriptionFragmentDoc}
  ${ProductAccessPeriodUsageFragmentDoc}
`;
export type ChangeUserSubscriptionPlanMutationFn = Apollo.MutationFunction<
  ChangeUserSubscriptionPlanMutation,
  ChangeUserSubscriptionPlanMutationVariables
>;

/**
 * __useChangeUserSubscriptionPlan__
 *
 * To run a mutation, you first call `useChangeUserSubscriptionPlan` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserSubscriptionPlan` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserSubscriptionPlan, { data, loading, error }] = useChangeUserSubscriptionPlan({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useChangeUserSubscriptionPlan(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserSubscriptionPlanMutation,
    ChangeUserSubscriptionPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserSubscriptionPlanMutation,
    ChangeUserSubscriptionPlanMutationVariables
  >(ChangeUserSubscriptionPlanDocument, options);
}
export type ChangeUserSubscriptionPlanHookResult = ReturnType<
  typeof useChangeUserSubscriptionPlan
>;
export type ChangeUserSubscriptionPlanMutationResult =
  Apollo.MutationResult<ChangeUserSubscriptionPlanMutation>;
export type ChangeUserSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserSubscriptionPlanMutation,
  ChangeUserSubscriptionPlanMutationVariables
>;
